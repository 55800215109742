'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable no-console */
var bindToConsole = function bindToConsole(consoleMethod, polyfill) {
  return consoleMethod ? consoleMethod.bind(console) : polyfill;
};

var logging = function () {
  var prefix = '';

  var consoleLog = function consoleLog() {
    var _console;

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    (_console = console).log.apply(_console, [prefix].concat(args));
  };

  var consoleError = function consoleError() {
    var _console2;

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    (_console2 = console).error.apply(_console2, [prefix].concat(args));
  };

  var consoleGroup = function consoleGroup() {
    consoleLog.apply(undefined, arguments);
    prefix += '> ';
  };

  var consoleGroupEnd = function consoleGroupEnd() {
    prefix = prefix.slice(0, -2);
  };

  return {
    log: consoleLog,
    error: consoleError,
    group: bindToConsole(console.group, consoleGroup),
    groupCollapsed: bindToConsole(console.groupCollapsed, consoleGroup),
    groupEnd: bindToConsole(console.groupEnd, consoleGroupEnd)
  };
}();

exports.default = logging;