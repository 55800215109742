'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var formatMessage = function formatMessage(operationType, operation, ellapsed) {
  var headerCss = ['color: gray; font-weight: lighter', // title
  'color: ' + (operationType === 'query' ? '#03A9F4' : 'red') + ';', // operationType
  'color: inherit;'];

  var parts = ['%c apollo', '%c' + operationType, '%c' + operation.operationName];

  if (operationType !== 'subscription') {
    parts.push('%c(in ' + ellapsed + ' ms)');
    headerCss.push('color: gray; font-weight: lighter;'); // time
  }

  return [parts.join(' ')].concat(headerCss);
};

exports.default = formatMessage;