'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _apolloLink = require('apollo-link');

var _formatMessage = require('./formatMessage');

var _formatMessage2 = _interopRequireDefault(_formatMessage);

var _logging = require('./logging');

var _logging2 = _interopRequireDefault(_logging);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } } /* eslint-disable import/no-unresolved,import/extensions */


var loggerLink = new _apolloLink.ApolloLink(function (operation, forward) {
  var startTime = new Date().getTime();

  return forward(operation).map(function (result) {
    var operationType = operation.query.definitions[0].operation;
    var ellapsed = new Date().getTime() - startTime;

    var group = (0, _formatMessage2.default)(operationType, operation, ellapsed);

    _logging2.default.groupCollapsed.apply(_logging2.default, _toConsumableArray(group));

    _logging2.default.log('INIT', operation);
    _logging2.default.log('RESULT', result);

    _logging2.default.groupEnd.apply(_logging2.default, _toConsumableArray(group));
    return result;
  });
});

exports.default = loggerLink;